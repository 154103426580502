import axios from "axios";
import { createContext, useState, useContext, useEffect } from "react";
import { useAuthContext } from "./AuthContext";

const INITIAL_STATES = {
  products: null,
  rooms: null,
  customers: null,
  isLoading: null,
  response: null,
  errorResponse: null,
  isError: null,
  products: null,
  appointments: null,
  coupons: null,
  revenue: null,
  yearlyRevenue: null,
  monthlyRevenue: null,
  refundedAmounts: {
    total: null,
    percentage: null,
  },
  weeklyStatsSale: null,
  recentTransactions: null,
  disableSlots: null,
  workingHours: null,
};

export const AdminContext = createContext(INITIAL_STATES);

export const AdminContextProvider = ({ children }) => {
  const [dataState, setDataState] = useState(INITIAL_STATES);
  const { user, token, isAdmin, isFrontDesk } = useAuthContext();
  //const baseUrl = "http://147.182.235.79:3004";
  const baseUrl =
    process.env.REACT_APP_DEVELOPMENT_MODE === "DEVELOPMENT"
      ? process.env.REACT_APP_TESTING_REACT_APP_URL
      : process.env.REACT_APP_URL;

  const fetchAllProducts = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/admin/all-products`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.success) {
        setDataState((prevData) => ({ ...prevData, products: data.data }));
      }
    } catch (error) {
      console.error("Error fetching Appointment data:", error);
    }
  };
  const fetchAllAppoinments = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/admin/all-appointments`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.success) {
        setDataState((prevData) => ({ ...prevData, appointments: data.data }));
      }
    } catch (error) {
      console.error("Error fetching Appointment data:", error);
    }
  };
  const fetchAllUsers = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/admin/users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.success) {
        setDataState((prevData) => ({ ...prevData, customers: data.users }));
      }
    } catch (error) {
      console.error("Error fetching Appointment data:", error);
    }
  };
  const fetchCoupons = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/admin/coupons`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.success) {
        setDataState((prevData) => ({ ...prevData, coupons: data.data }));
      }
    } catch (error) {
      console.error("Error fetching coupons data:", error);
    }
  };
  const fetchSingleCoupon = async (id) => {
    try {
      const { data } = await axios.get(`${baseUrl}/admin/coupon/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.success) {
        return data.data;
      }
    } catch (error) {
      console.error("Error fetching Appointment data:", error);
    }
  };
  const fetchSingleUser = async (id) => {
    try {
      const { data } = await axios.get(`${baseUrl}/admin/user/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.success) {
        return data.data;
      }
    } catch (error) {
      console.error("Error fetching Appointment data:", error);
    }
  };
  const fetchSingleAppointment = async (id) => {
    try {
      const { data } = await axios.get(`${baseUrl}/admin/appointment/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.success) {
        return data.data;
      }
    } catch (error) {
      console.error("Error fetching Appointment data:", error);
    }
  };
  const fetchRevenue = async (id) => {
    try {
      const { data } = await axios.get(`${baseUrl}/admin/revenue`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.success) {
        setDataState((prevData) => ({
          ...prevData,
          revenue: data.data,
        }));
      }
    } catch (error) {
      console.error("Error fetching Appointment data:", error);
    }
  };
  const fetchYearlyRevenue = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/admin/yearly-revenue`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.success) {
        setDataState((prevData) => ({
          ...prevData,
          yearlyRevenue: data.data,
        }));
      }
    } catch (error) {
      console.error("Error fetching Appointment data:", error);
    }
  };

  const fetchWorkingHours = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/admin/working-hours`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.success) {
        setDataState((prevData) => ({
          ...prevData,
          workingHours: data.data,
        }));
      }
    } catch (error) {
      console.error("Error fetching Appointment data:", error);
    }
  };

  const refundedAmounts = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/admin/refunded-amounts`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.success) {
        setDataState((prevData) => ({
          ...prevData,
          refundedAmounts: {
            total: data.data,
            percentage: data.percentage,
          },
        }));
      }
    } catch (error) {}
  };
  const fetchRevenueByMonth = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/admin/monthly-revenue`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.success) {
        setDataState((prevData) => ({
          ...prevData,
          monthlyRevenue: data.data,
        }));
      }
    } catch (error) {
      console.error("Error fetching Appointment data:", error);
    }
  };
  const fetchWeeklyStats = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/admin/weekly-stats`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.success) {
        setDataState((prevData) => ({
          ...prevData,
          weeklyStatsSale: data.data,
        }));
      }
    } catch (error) {
      console.error("Error fetching Appointment data:", error);
    }
  };
  const fetchRecentTransactions = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/admin/recent-transactions`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.success) {
        setDataState((prevData) => ({
          ...prevData,
          recentTransactions: data.data,
        }));
      }
    } catch (error) {
      console.error("Error fetching Appointment data:", error);
    }
  };
  const fetchDisableSlots = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/admin/misc-timeslot`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.success) {
        setDataState((prevData) => ({
          ...prevData,
          disableSlots: data.data,
        }));
      }
    } catch (error) {
      console.error("Error fetching Appointment data:", error);
    }
  };

  useEffect(() => {
    if (isAdmin) {
      fetchWorkingHours();
      fetchAllProducts();
      fetchDisableSlots();
      fetchAllAppoinments();
      fetchAllUsers();
      fetchRevenue();
      fetchYearlyRevenue();
      refundedAmounts();
      fetchRevenueByMonth();
      fetchWeeklyStats();
      fetchCoupons();
      fetchRecentTransactions();
    }
  }, [isAdmin]);

  useEffect(() => {
    if (isFrontDesk) {
      fetchAllProducts();
      fetchAllAppoinments();
      fetchAllUsers();
      fetchCoupons();
      fetchWorkingHours();
    }
  }, [isFrontDesk]);

  return (
    <AdminContext.Provider
      value={{
        fetchAllAppoinments,
        fetchAllUsers,
        fetchSingleUser,
        fetchSingleAppointment,
        fetchRevenue,
        fetchCoupons,
        fetchYearlyRevenue,
        fetchSingleCoupon,
        fetchAllProducts,
        fetchDisableSlots,
        fetchWorkingHours,
        ...dataState,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};

export const useAdminContext = () => useContext(AdminContext);
