import React, { useEffect, useRef, useState } from "react";
import { useStateContext } from "../contexts/ContextProvider";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useFetchContext } from "../contexts/FetchContext";
import { useNavigate } from "react-router-dom";

import moment from "moment";
import { usePostContext } from "../contexts/PostContext";
import { toast } from "react-toastify";
import axios from "axios";
import {
  CardElement,
  useStripe,
  useElements,
  Elements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useAdminContext } from "../contexts/AdminContext";

const stripePromise = loadStripe(
  "pk_live_51P2FxUA28JSbW1Km2U7ys330z5uLUkfivPv6SHbkIXerz3X7opjxSKg9V19kWGUxS7Dn9AckHd0ysDVooQf31fdT00ZR9cooBj"
  // "pk_test_51P2FxUA28JSbW1KmQMSLZ6Atm0t3vYxkHsl6czGuPv4jM63MWYe6KzWfIZiguWfcNkxZsHtQTwcX61NHA8WdgTc100nwsqLr3Y"
);

const baseUrl =
  process.env.REACT_APP_DEVELOPMENT_MODE === "DEVELOPMENT"
    ? process.env.REACT_APP_TESTING_REACT_APP_URL
    : process.env.REACT_APP_URL;

const Form = () => {
  const { currentColor, currentMode } = useStateContext();
  const { products, fetchAvailableSlots } = useFetchContext();
  const { PostWalkinAppointmentData, VerifyCoupon } = usePostContext();
  const { fetchAllAppoinments } = useAdminContext();
  const [isApplyCoupon, setIsApplyCoupon] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const [productDetails, setProductDetails] = useState(null);
  const [productServices, setProductServices] = useState(null);
  const [slotData, setSlotData] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);
  const [couponData, setCouponData] = useState(null);
  const inputRef = useRef();
  const handleApplyCoupon = async () => {
    let data = {
      code: inputRef.current.value,
    };
    try {
      setIsApplyCoupon(true);
      const couponData = await VerifyCoupon(data);
      if (couponData._id) {
        setCouponData(couponData);
        // Calculate discounted price
        const currentPrice = productServices.filter(
          ({ _id }) => _id === formik.values.serviceId
        )[0]?.price;
        const discount =
          couponData.type === "percentage" ? couponData.discount : 0;
        const discountedPrice = ((currentPrice - discount) * 1.13).toFixed(2);

        // Update Formik price field with discounted price
        formik.setFieldValue("price", discountedPrice || "");
        setIsApplyCoupon(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsApplyCoupon(false);
    }
  };

  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    setBtnLoading(true);
    if (!stripe || !elements) return;
    try {
      const {
        data: { clientSecret },
      } = await axios.post(`${baseUrl}/shop/create-payment-intent`, {
        amount: values.price,
      });

      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: values.name,
            email: values.email,
          },
        },
      });
      if (result.error) {
        toast.error(result.error.message);
        console.error("Payment Error:", result.error);
      } else {
        if (result.paymentIntent.status === "succeeded") {
          let data = {
            ...values,
            isPaid: true,
            payment_result: {
              id: result.paymentIntent.id,
              status: "Approved",
              email_address: values.email,
              isRefunded: false,
            },
            date: moment(values.date).format("YYYY-M-DD"),
          };
          await PostWalkinAppointmentData(data);
          toast.success("Payment Success");
          formik.resetForm();
          fetchAllAppoinments();
          navigate("/admin/appointments");
        }
      }
    } catch (error) {
      toast.error("Payment Failed");
      console.error("Payment Error:", error);
    } finally {
      setBtnLoading(false);
    }
    setBtnLoading(false);
  };

  const handleProductChange = (value) => {
    formik.setFieldValue("product", value);
    const productDetail = products.filter(({ _id }) => _id === value)[0];
    setProductDetails(productDetail?._id ? productDetail : null);
    setProductServices(productDetail?.services);
    formik.setFieldValue("description", productDetail?.description || "");
    formik.setFieldValue("selectedRoom", "");
    formik.setFieldValue("price", "");
    formik.setFieldValue("date", "");
    formik.setFieldValue("startTime", "");
  };
  const handleServiceChange = (value) => {
    formik.setFieldValue("serviceId", value);
    const currentPrice = productServices.filter(({ _id }) => _id === value)[0]
      ?.price;
    formik.setFieldValue("price", (currentPrice * 1.13).toFixed(2) || "");
    formik.setFieldValue("date", "");
    formik.setFieldValue("startTime", "");
  };
  const handleRoomChange = (room) => {
    formik.setFieldValue("selectedRoom", room);
    formik.setFieldValue("date", "");
    formik.setFieldValue("startTime", "");
  };
  const handleDateChange = async (date) => {
    let formattedDate = moment(date).format("YYYY-MM-DD");
    let dataToSend = {
      productId: productDetails._id,
      selectedDate: formattedDate,
      roomId: formik.values.selectedRoom,
    };
    let data = await fetchAvailableSlots(dataToSend);
    setSlotData(data);
    formik.setFieldValue("date", date);
    formik.setFieldValue("startTime", null);
  };
  const handleTimeChange = async (time) => {
    const startTime = moment(time, "hh:mma");
    const endTime = productDetails?.name?.includes("Red Light Therapy")
      ? startTime.add(30, "minutes")
      : startTime.add(50, "minutes");
    const formattedEndTime = endTime.format("hh:mma");
    formik.setFieldValue("startTime", time);
    formik.setFieldValue("endTime", formattedEndTime);
  };

  const cardStyle = {
    style: {
      base: {
        iconColor: currentMode === "Dark" ? "#fff" : "#000",
        color: currentMode === "Dark" ? "#fff" : "#000",
        fontWeight: "500",
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "14px",
        // marginTop: "10px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": {
          color: "#000",
        },
        "::placeholder": {
          color: currentMode === "Dark" ? "#fff" : "#000",
        },
      },
      invalid: {
        iconColor: currentMode === "Dark" ? "#fff" : "#000",
        color: currentMode === "Dark" ? "#fff" : "#000",
      },
      // marginTop: "10px",
    },
  };

  useEffect(() => {}, [productServices, productDetails]);
  const WalkInCustomer = Yup.object({
    name: Yup.string().required("Required"),
    email: Yup.string().email("Enter valid email").required("Required"),
    product: Yup.string().required("Required"),
    selectedRoom: Yup.string().required("Required"),
    serviceId: Yup.string().required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      product: "",
      serviceId: "",
      price: "",
      description: "",
      selectedRoom: "",
      date: "",
      startTime: "",
      endTime: "",
    },
    validationSchema: WalkInCustomer,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  return (
    <section className="dark:text-white-shade text-black-shade body-font overflow-hidden">
      <div className="container px-5 py-14 mx-auto">
        <h1
          style={{ color: currentColor }}
          className="text-3xl text-center font-medium title-font mb-10 tracking-widest"
        >
          Walk In Customer
        </h1>
        <div className=" mx-auto flex flex-wrap">
          <div className=" w-full mb-6 ">
            <form
              onSubmit={formik.handleSubmit}
              className="flex-col mx-auto max-w-xl"
            >
              <div className="mb-2">
                <h1
                  style={{ color: currentColor }}
                  className="text-xl font-medium title-font mb-2 tracking-widest"
                >
                  Client Details
                </h1>
                <div className="flex items-center gap-5">
                  <div className="relative z-0 w-full mb-5 group">
                    <input
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="block py-2.5 px-0 w-full text-sm text-black-shade bg-transparent border-0 border-b-2 border-black-shade appearance-none dark:text-white-shade dark:border-white-shade  focus:outline-none focus:ring-0  peer"
                      placeholder=" "
                    />
                    <label
                      htmlFor="name"
                      className="peer-focus:font-medium absolute text-sm text-black-shade dark:text-white-shade duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Full Name
                    </label>
                    {formik.touched.name && formik.errors.name && (
                      <div className="text-black-shade dark:text-white-shade text-xs mt-1">
                        {formik.errors.name}
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex items-center gap-5">
                  <div className="relative z-0 w-full mb-5 group">
                    <input
                      type="email"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="block py-2.5 px-0 w-full text-sm text-black-shade bg-transparent border-0 border-b-2 border-black-shade appearance-none dark:text-white-shade dark:border-white-shade  focus:outline-none focus:ring-0  peer"
                      placeholder=" "
                    />
                    <label
                      htmlFor="email"
                      className="peer-focus:font-medium absolute text-sm text-black-shade dark:text-white-shade duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Email
                    </label>
                    {formik.touched.email && formik.errors.email && (
                      <div className="text-black-shade dark:text-white-shade text-xs mt-1">
                        {formik.errors.email}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="mb-4">
                <h1
                  style={{ color: currentColor }}
                  className="text-xl font-medium title-font mb-2 tracking-widest"
                >
                  Product Details
                </h1>
                <div className="flex items-center gap-5">
                  <div className="relative z-0 w-full mb-5 group">
                    <label htmlFor="product" className="sr-only">
                      Select Product
                    </label>
                    <select
                      id="product"
                      value={formik.values.product}
                      onChange={(e) => handleProductChange(e.target.value)}
                      onBlur={formik.handleBlur}
                      className="block py-2.5 px-0 w-full text-sm text-black-shade bg-transparent border-0 border-b-2 border-black-shade appearance-none dark:text-white-shade dark:border-white-shade focus:outline-none focus:ring-0 focus:border-black-shade peer"
                    >
                      <option
                        className="block py-2.5 px-0 w-full text-sm text-black-shade border-0 border-b-2 border-black-shade dark:border-white-shade"
                        value=""
                      >
                        Select Product
                      </option>
                      {products?.map((product) => (
                        <option
                          className="block py-2.5 px-0 w-full text-sm text-black-shade border-0 border-b-2 border-black-shade dark:border-white-shade"
                          key={product._id}
                          value={product._id}
                        >
                          {product.name}
                        </option>
                      ))}
                    </select>
                    {formik.touched.product && formik.errors.product && (
                      <div className="text-black-shade dark:text-white-shade text-xs mt-1">
                        {formik.errors.product}
                      </div>
                    )}
                  </div>
                  <div className="relative z-0 w-full mb-5 group">
                    <label htmlFor="serviceId" className="sr-only">
                      Select Service
                    </label>
                    <select
                      id="serviceId"
                      value={formik.values.serviceId}
                      onChange={(e) => handleServiceChange(e.target.value)}
                      disabled={productServices == null ? true : false}
                      onBlur={formik.handleBlur}
                      className="block py-2.5 px-0 w-full text-sm text-black-shade bg-transparent border-0 border-b-2 border-black-shade appearance-none dark:text-white-shade dark:border-white-shade focus:outline-none focus:ring-0 focus:border-black-shade peer"
                    >
                      <option
                        className="block py-2.5 px-0 w-full text-sm text-black-shade border-0 border-b-2 border-black-shade dark:border-white-shade"
                        value=""
                      >
                        Select Service
                      </option>
                      {productServices?.map((service) => (
                        <option
                          className="block py-2.5 px-0 w-full text-sm text-black-shade border-0 border-b-2 border-black-shade dark:border-white-shade"
                          key={service._id}
                          value={service._id}
                        >
                          {service.name}
                        </option>
                      ))}
                    </select>
                    {formik.touched.serviceId && formik.errors.serviceId && (
                      <div className="text-black-shade dark:text-white-shade text-xs mt-1">
                        {formik.errors.serviceId}
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex items-center gap-5">
                  <div className="relative z-0 w-full mb-5 group">
                    <label htmlFor="room" className="sr-only">
                      Room
                    </label>
                    <select
                      id="selectedRoom"
                      value={formik.values.selectedRoom}
                      onChange={(e) => handleRoomChange(e.target.value)}
                      disabled={productDetails === null ? true : false}
                      onBlur={formik.handleBlur}
                      className="block py-2.5 px-0 w-full text-sm text-black-shade bg-transparent border-0 border-b-2 border-black-shade appearance-none dark:text-white-shade dark:border-white-shade focus:outline-none focus:ring-0 focus:border-black-shade peer"
                    >
                      <option
                        className="block py-2.5 px-0 w-full text-sm text-black-shade border-0 border-b-2 border-black-shade dark:border-white-shade"
                        value=""
                      >
                        Select Room
                      </option>
                      {productDetails?.room?.map((room, i) => (
                        <option
                          className="block py-2.5 px-0 w-full text-sm text-black-shade border-0 border-b-2 border-black-shade dark:border-white-shade"
                          key={room._id}
                          value={room._id}
                        >
                          {room.name}
                        </option>
                      ))}
                    </select>
                    {formik.touched.selectedRoom &&
                      formik.errors.selectedRoom && (
                        <div className="text-black-shade dark:text-white-shade text-xs mt-1">
                          {formik.errors.selectedRoom}
                        </div>
                      )}
                  </div>
                  <div className="relative z-0 w-full mb-5 group">
                    <input
                      type="number"
                      name="price"
                      value={formik.values.price}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="block py-2.5 px-0 w-full text-sm text-black-shade bg-transparent border-0 border-b-2 border-black-shade appearance-none dark:text-white-shade dark:border-white-shade  focus:outline-none focus:ring-0  peer"
                      placeholder=" "
                      disabled
                    />
                    <label
                      htmlFor="price"
                      className="peer-focus:font-medium absolute text-sm text-black-shade dark:text-white-shade duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Price
                    </label>
                    {formik.touched.price && formik.errors.price && (
                      <div className="text-black-shade dark:text-white-shade text-xs mt-1">
                        {formik.errors.price}
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex items-center gap-5">
                  <div className="relative z-0 w-full mb-5 group">
                    <input
                      name="date"
                      type="date"
                      value={formik.values.date}
                      onChange={(e) => handleDateChange(e.target.value)}
                      onBlur={formik.handleBlur}
                      className="block py-2.5 px-0 w-full text-sm text-black-shade bg-transparent border-0 border-b-2 border-black-shade appearance-none dark:text-white-shade dark:border-white-shade  focus:outline-none focus:ring-0  peer"
                      required
                      placeholder=""
                      disabled={
                        formik.values.selectedRoom === "" ? true : false
                      }
                    />
                    <label
                      htmlFor="date"
                      className="peer-focus:font-medium absolute text-sm text-black-shade dark:text-white-shade duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Date
                    </label>
                    {formik.touched.date && formik.errors.date && (
                      <div className="text-black-shade dark:text-white-shade text-xs mt-1">
                        {formik.errors.date}
                      </div>
                    )}
                  </div>
                  <div className="relative z-0 w-full mb-5 group">
                    <label htmlFor="startTime" className="sr-only">
                      Select Time
                    </label>
                    <select
                      id="startTime"
                      value={formik.values.startTime}
                      onChange={(e) => handleTimeChange(e.target.value)}
                      disabled={formik.values.date === "" ? true : false}
                      onBlur={formik.handleBlur}
                      className="block py-2.5 px-0 w-full text-sm text-black-shade bg-transparent border-0 border-b-2 border-black-shade appearance-none dark:text-white-shade dark:border-white-shade focus:outline-none focus:ring-0 focus:border-black-shade peer"
                    >
                      <option
                        className="block py-2.5 px-0 w-full text-sm text-black-shade border-0 border-b-2 border-black-shade dark:border-white-shade"
                        value=""
                      >
                        Select Time
                      </option>
                      {slotData?.map((time, i) => (
                        <option
                          className="block py-2.5 px-0 w-full text-sm text-black-shade border-0 border-b-2 border-black-shade dark:border-white-shade"
                          key={i}
                          value={time}
                        >
                          {time}
                        </option>
                      ))}
                    </select>
                    {formik.touched.startTime && formik.errors.startTime && (
                      <div className="text-black-shade dark:text-white-shade text-xs mt-1">
                        {formik.errors.startTime}
                      </div>
                    )}
                  </div>
                </div>
                <div className="relative z-0 w-full mb-5 group">
                  <label
                    htmlFor="description"
                    className="block mb-2 text-sm font-medium text-black-shade dark:text-white-shade"
                  >
                    Description
                  </label>
                  <textarea
                    id="description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    rows="4"
                    className="block p-2.5 w-full text-sm text-black-shade bg-gray-50 rounded-lg border border-black-shade dark:bg-main-dark-bg dark:border-white-shade dark:placeholder-gray-300 placeholder-gray-700 dark:text-white-shade "
                    placeholder="Add Description"
                    disabled
                  ></textarea>
                  {formik.touched.description && formik.errors.description && (
                    <div className="text-black-shade dark:text-white-shade text-xs mt-1">
                      {formik.errors.description}
                    </div>
                  )}
                </div>
              </div>
              <div className="flex items-center my-5">
                <input
                  ref={inputRef}
                  type="text"
                  id="applyCode"
                  className="bg-transparent border border-white-shade text-white-shade rounded-2xl w-full p-2 text-xs"
                  placeholder="Coupon Code"
                />
                <button
                  onClick={handleApplyCoupon}
                  disabled={
                    formik.values.price === "" || isApplyCoupon ? true : false
                  }
                  className="transition duration-300 hover:text-black-shade hover:bg-white-shade text-white-shade text-xs border border-white-shade ml-2 px-3 py-2 rounded-2xl disabled:text-white-shade disabled:bg-gray-400 dark:disabled:bg-gray-600"
                >
                  Apply
                </button>
              </div>
              <div className="mb-2">
                <h1
                  style={{ color: currentColor }}
                  className="text-xl font-medium title-font mb-2 tracking-widest"
                >
                  Payment Details
                </h1>
                <div className="my-5 dark:border-white-shade border-black-shade">
                  <div className="border-2 dark:border-white-shade border-black-shade rounded-md p-4 w-full">
                    <CardElement options={cardStyle} />
                  </div>
                </div>
              </div>
              <div className=" w-full my-2 flex justify-end ">
                <button
                  disabled={btnLoading ? true : false}
                  style={{ backgroundColor: currentColor }}
                  className="text-black-shade dark:text-white focus:outline-none disabled:!text-white-shade disabled:!bg-gray-400 dark:disabled:!bg-gray-600 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
                >
                  {btnLoading || isApplyCoupon
                    ? "Submitting..."
                    : `Pay ${
                        formik.values.price
                          ? (formik.values.price * 1.13).toFixed(2)
                          : "--"
                      }$`}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

const WalkInCustomer = () => {
  const appearance = {
    theme: "stripe",
  };
  const options = {
    appearance,
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <Form />
    </Elements>
  );
};

export default WalkInCustomer;
