import React, { useEffect, useState } from "react";
import { useStateContext } from "../contexts/ContextProvider";
import { useFormik } from "formik";
import * as Yup from "yup";
import { usePostContext } from "../contexts/PostContext";
import { useNavigate } from "react-router-dom";
import { useAdminContext } from "../contexts/AdminContext";
const WorkingHours = () => {
  const { currentColor } = useStateContext();
  const { UpdateWorkingHours } = usePostContext();
  const { workingHours } = useAdminContext();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (workingHours) {
      formik.setFieldValue(
        "mondayToFridayStartTime",
        workingHours?.mondayToFriday?.startTime
      );
      formik.setFieldValue(
        "mondayToFridayEndTime",
        workingHours?.mondayToFriday?.endTime
      );
      formik.setFieldValue(
        "saturdayStartTime",
        workingHours?.saturday?.startTime
      );
      formik.setFieldValue("saturdayEndTime", workingHours?.saturday?.endTime);
      formik.setFieldValue("sundayStartTime", workingHours?.sunday?.startTime);
      formik.setFieldValue("sundayEndTime", workingHours?.sunday?.endTime);
    }
  }, [workingHours]);

  const handleSubmit = async (values) => {
    setLoading(true);
    let data = {
      workingHours: {
        mondayToFriday: {
          startTime: values.mondayToFridayStartTime,
          endTime: values.mondayToFridayEndTime,
        },
        saturday: {
          startTime: values.saturdayStartTime,
          endTime: values.saturdayEndTime,
        },
        sunday: {
          startTime: values.sundayStartTime,
          endTime: values.sundayEndTime,
        },
      },
    };
    await UpdateWorkingHours(data);
    setLoading(false);
  };

  //FORM SCHEMA FOR FORMIK
  const WorkingHoursSchema = Yup.object({
    mondayToFridayStartTime: Yup.string().required("Start Time is Required"),
    mondayToFridayEndTime: Yup.string().required("End Time is Required"),
    saturdayStartTime: Yup.string().required("Start Time is Required"),
    saturdayEndTime: Yup.string().required("End Time is Required"),
    sundayStartTime: Yup.string().required("Start Time is Required"),
    sundayEndTime: Yup.string().required("End Time is Required"),
  });

  //FORMIK INTIALIZATION FOR FORM
  const formik = useFormik({
    initialValues: {
      mondayToFridayStartTime: "",
      mondayToFridayEndTime: "",
      saturdayStartTime: "",
      saturdayEndTime: "",
      sundayStartTime: "",
      sundayEndTime: "",
    },
    validationSchema: WorkingHoursSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  return (
    <section className="dark:text-white-shade text-black-shade body-font overflow-hidden">
      <div className="container px-5 py-14 mx-auto">
        <h1 className="text-3xl text-center font-medium title-font mb-10 tracking-widest">
          Working Hours
        </h1>
        <div className="lg:w-4/5 mx-auto flex items-center flex-wrap">
          <div className="w-full lg:pl-10 lg:py-6 mb-6 lg:mb-0">
            <form className="max-w-md mx-auto" onSubmit={formik.handleSubmit}>
              <h2 className="text-xl text-left font-medium title-font mb-10 tracking-widest">
                Monday To Friday
              </h2>
              <div className="relative z-0 w-full mb-5 group">
                <input
                  type="time"
                  name="mondayToFridayStartTime"
                  className="block py-2.5 px-0 w-full text-sm text-black-shade bg-transparent border-0 border-b-2 border-black-shade appearance-none dark:text-white-shade dark:border-white-shade focus:outline-none focus:ring-0 peer"
                  placeholder=""
                  value={formik.values.mondayToFridayStartTime}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                />
                <label
                  htmlFor="mondayToFridayStartTime"
                  className="peer-focus:font-medium absolute text-sm text-black-shade dark:text-white-shade duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Start Time
                </label>
                {formik.touched.mondayToFridayStartTime &&
                  formik.errors.mondayToFridayStartTime && (
                    <div className="text-black-shade dark:text-white-shade text-xs mt-1">
                      {formik.errors.mondayToFridayStartTime}
                    </div>
                  )}
              </div>
              <div className="relative z-0 w-full mb-5 group">
                <input
                  type="time"
                  name="mondayToFridayEndTime"
                  className="block py-2.5 px-0 w-full text-sm text-black-shade bg-transparent border-0 border-b-2 border-black-shade appearance-none dark:text-white-shade dark:border-white-shade focus:outline-none focus:ring-0 peer"
                  placeholder=""
                  value={formik.values.mondayToFridayEndTime}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                />
                <label
                  htmlFor="mondayToFridayEndTime"
                  className="peer-focus:font-medium absolute text-sm text-black-shade dark:text-white-shade duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:End-0  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  End Time
                </label>
                {formik.touched.mondayToFridayEndTime &&
                  formik.errors.mondayToFridayEndTime && (
                    <div className="text-black-shade dark:text-white-shade text-xs mt-1">
                      {formik.errors.mondayToFridayEndTime}
                    </div>
                  )}
              </div>
              <h2 className="text-xl text-left font-medium title-font mb-10 tracking-widest">
                Saturday
              </h2>
              <div className="relative z-0 w-full mb-5 group">
                <input
                  type="time"
                  name="saturdayStartTime"
                  className="block py-2.5 px-0 w-full text-sm text-black-shade bg-transparent border-0 border-b-2 border-black-shade appearance-none dark:text-white-shade dark:border-white-shade focus:outline-none focus:ring-0 peer"
                  placeholder=""
                  value={formik.values.saturdayStartTime}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                />
                <label
                  htmlFor="saturdayStartTime"
                  className="peer-focus:font-medium absolute text-sm text-black-shade dark:text-white-shade duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Start Time
                </label>
                {formik.touched.saturdayStartTime &&
                  formik.errors.saturdayStartTime && (
                    <div className="text-black-shade dark:text-white-shade text-xs mt-1">
                      {formik.errors.saturdayStartTime}
                    </div>
                  )}
              </div>
              <div className="relative z-0 w-full mb-5 group">
                <input
                  type="time"
                  name="saturdayEndTime"
                  className="block py-2.5 px-0 w-full text-sm text-black-shade bg-transparent border-0 border-b-2 border-black-shade appearance-none dark:text-white-shade dark:border-white-shade focus:outline-none focus:ring-0 peer"
                  placeholder=""
                  value={formik.values.saturdayEndTime}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                />
                <label
                  htmlFor="saturdayEndTime"
                  className="peer-focus:font-medium absolute text-sm text-black-shade dark:text-white-shade duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:End-0  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  End Time
                </label>
                {formik.touched.saturdayEndTime &&
                  formik.errors.saturdayEndTime && (
                    <div className="text-black-shade dark:text-white-shade text-xs mt-1">
                      {formik.errors.saturdayEndTime}
                    </div>
                  )}
              </div>
              <h2 className="text-xl text-left font-medium title-font mb-10 tracking-widest">
                Sunday
              </h2>
              <div className="relative z-0 w-full mb-5 group">
                <input
                  type="time"
                  name="sundayStartTime"
                  className="block py-2.5 px-0 w-full text-sm text-black-shade bg-transparent border-0 border-b-2 border-black-shade appearance-none dark:text-white-shade dark:border-white-shade focus:outline-none focus:ring-0 peer"
                  placeholder=""
                  value={formik.values.sundayStartTime}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                />
                <label
                  htmlFor="sundayStartTime"
                  className="peer-focus:font-medium absolute text-sm text-black-shade dark:text-white-shade duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Start Time
                </label>
                {formik.touched.sundayStartTime &&
                  formik.errors.sundayStartTime && (
                    <div className="text-black-shade dark:text-white-shade text-xs mt-1">
                      {formik.errors.sundayStartTime}
                    </div>
                  )}
              </div>
              <div className="relative z-0 w-full mb-5 group">
                <input
                  type="time"
                  name="sundayEndTime"
                  className="block py-2.5 px-0 w-full text-sm text-black-shade bg-transparent border-0 border-b-2 border-black-shade appearance-none dark:text-white-shade dark:border-white-shade focus:outline-none focus:ring-0 peer"
                  placeholder=""
                  value={formik.values.sundayEndTime}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                />
                <label
                  htmlFor="sundayEndTime"
                  className="peer-focus:font-medium absolute text-sm text-black-shade dark:text-white-shade duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:End-0  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  End Time
                </label>
                {formik.touched.sundayEndTime &&
                  formik.errors.sundayEndTime && (
                    <div className="text-black-shade dark:text-white-shade text-xs mt-1">
                      {formik.errors.sundayEndTime}
                    </div>
                  )}
              </div>

              <div className="w-full flex justify-end my-6">
                <button
                  style={{ backgroundColor: currentColor }}
                  type="submit"
                  disabled={loading}
                  className="text-white hover:bg-blue-800 disabled:!bg-gray-700 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:hover:bg-blue-700 "
                >
                  {loading ? "Submitting" : "Submit"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WorkingHours;
