import React, { useEffect, useRef, useState } from "react";
import { useStateContext } from "../contexts/ContextProvider";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useFetchContext } from "../contexts/FetchContext";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { usePostContext } from "../contexts/PostContext";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { FaMinusCircle } from "react-icons/fa";
const CreateCoupon = () => {
  const { currentColor } = useStateContext();
  const { PostCouponData } = usePostContext();
  const navigate = useNavigate();
  const [btnLoading, setBtnLoading] = useState(false);
  const [isUnlimitedToggle, setIsUnlimitedToggle] = useState(false);

  const handleSubmit = async (values) => {
    setBtnLoading(true);
    await PostCouponData(values);
    formik.resetForm();
    setBtnLoading(false);
    navigate("/admin/coupons");
  };
  function couponGenerator() {
    var coupon = "";
    var possible = "abcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < 6; i++) {
      coupon += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    let code = coupon.toLocaleUpperCase();
    formik.setFieldValue("code", code);
  }

  const Coupon = Yup.object({
    code: Yup.string().required("Required"),
    type: Yup.string().required("Required"),
    discount: Yup.number().required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      code: "",
      type: "",
      discount: "",
      isUnlimited: false,
    },
    validationSchema: Coupon,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  return (
    <section className="dark:text-white-shade text-black-shade body-font overflow-hidden">
      <div className="container px-5 py-14 mx-auto">
        <h1
          style={{ color: currentColor }}
          className="text-3xl text-center font-medium title-font mb-10 tracking-widest"
        >
          Create Coupon
        </h1>
        <div className=" mx-auto flex flex-wrap">
          <div className=" w-full mb-6 ">
            <form
              onSubmit={formik.handleSubmit}
              className="flex-col mx-auto max-w-xl"
            >
              <div className="flex items-center gap-5">
                <div className="relative z-0 w-full mb-5 group">
                  <input
                    name="code"
                    value={formik.values.code}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="block py-2.5 px-0 w-full text-sm text-black-shade bg-transparent border-0 border-b-2 border-black-shade appearance-none dark:text-white-shade dark:border-white-shade  focus:outline-none focus:ring-0  peer"
                    placeholder=" "
                    required
                  />
                  <label
                    htmlFor="code"
                    className="peer-focus:font-medium absolute text-sm text-black-shade dark:text-white-shade duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Coupon Code
                  </label>
                  {formik.touched.code && formik.errors.code && (
                    <div className="text-black-shade dark:text-white-shade text-xs mt-1">
                      {formik.errors.code}
                    </div>
                  )}
                </div>
                <button
                  type="button"
                  onClick={couponGenerator}
                  style={{ backgroundColor: currentColor }}
                  className="text-black-shade text-nowrap dark:text-white focus:outline-none disabled:!text-white-shade disabled:!bg-gray-400 dark:disabled:!bg-gray-600 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
                >
                  Generate Code
                </button>
              </div>
              <div className="flex items-center gap-5">
                <div className="relative z-0 w-full mb-5 group">
                  <label htmlFor="type" className="sr-only">
                    Select Type
                  </label>
                  <select
                    id="type"
                    value={formik.values.type}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="block py-2.5 px-0 w-full text-sm text-black-shade bg-transparent border-0 border-b-2 border-black-shade appearance-none dark:text-white-shade dark:border-white-shade focus:outline-none focus:ring-0 focus:border-black-shade peer"
                  >
                    <option
                      className="block py-2.5 px-0 w-full text-sm text-black-shade border-0 border-b-2 border-black-shade dark:border-white-shade"
                      value=""
                    >
                      Select Type
                    </option>
                    <option
                      className="block py-2.5 px-0 w-full text-sm text-black-shade border-0 border-b-2 border-black-shade dark:border-white-shade"
                      value="fixed"
                    >
                      fixed
                    </option>
                    <option
                      className="block py-2.5 px-0 w-full text-sm text-black-shade border-0 border-b-2 border-black-shade dark:border-white-shade"
                      value="percentage"
                    >
                      Percentage
                    </option>
                  </select>
                  {formik.touched.type && formik.errors.type && (
                    <div className="text-black-shade dark:text-white-shade text-xs mt-1">
                      {formik.errors.type}
                    </div>
                  )}
                </div>
              </div>
              <div className="flex items-center gap-5">
                <div className="relative z-0 w-full mb-5 group">
                  <input
                    type="number"
                    name="discount"
                    value={formik.values.discount}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="block py-2.5 px-0 w-full text-sm text-black-shade bg-transparent border-0 border-b-2 border-black-shade appearance-none dark:text-white-shade dark:border-white-shade  focus:outline-none focus:ring-0  peer"
                    placeholder=" "
                    required
                  />
                  <label
                    htmlFor="discount"
                    className="peer-focus:font-medium absolute text-sm text-black-shade dark:text-white-shade duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Discount
                  </label>
                  <div className="absolute inset-y-0 right-0 flex items-center pr-2">
                    {formik.values.type === ""
                      ? "--"
                      : formik.values.type === "fixed"
                      ? "$"
                      : "%"}
                  </div>
                  {formik.touched.discount && formik.errors.discount && (
                    <div className="text-black-shade dark:text-white-shade text-xs mt-1">
                      {formik.errors.discount}
                    </div>
                  )}
                </div>
              </div>
              <div className="flex my-4 items-center w-full justify-between">
                <span className="dark:text-white-shade text-black-shade font-semibold text-base">
                  Unlimited
                </span>
                <div className="">
                  <label className="inline-flex items-center cursor-pointer">
                    <input
                      name="isAvailable"
                      type="checkbox"
                      value={formik.values.isUnlimited}
                      className="sr-only peer"
                      checked={isUnlimitedToggle}
                      onChange={(e) => {
                        setIsUnlimitedToggle(() => {
                          formik.setFieldValue(
                            "isUnlimited",
                            !isUnlimitedToggle
                          );
                          return !isUnlimitedToggle;
                        });
                      }}
                    />
                    <div className="relative w-9 h-5 bg-black-shade peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-blue-600"></div>
                  </label>
                </div>
              </div>
              <div className=" w-full my-2 flex justify-end ">
                <button
                  type="submit"
                  disabled={btnLoading ? true : false}
                  style={{ backgroundColor: currentColor }}
                  className="text-black-shade dark:text-white focus:outline-none disabled:!text-white-shade disabled:!bg-gray-400 dark:disabled:!bg-gray-600 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
                >
                  {btnLoading ? "Submitting..." : "Submit"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CreateCoupon;
