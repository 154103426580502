import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import "../../css/calendar.css";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import ShopTimeSlot from "./Components/ShopTimeSlot";
import { useAuthContext } from "../../contexts/AuthContext";
import { useFetchContext } from "../../contexts/FetchContext";
import Loading from "../../components/Loading";
import AppointmentDetails from "./Components/AppointmentDetails";
import { useCartContext } from "../../contexts/CartContext";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useStateContext } from "../../contexts/ContextProvider";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";

const ProductDetail = () => {
  const { isLoggedIn } = useAuthContext();
  const { fetchSingleProduct, fetchAvailableSlots } = useFetchContext();
  const { addItemToCart, item } = useCartContext();
  const { handleClick } = useStateContext();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [currentWeekDates, setCurrentWeekDates] = useState([]);
  const [productDetails, setProductDetails] = useState("");
  const [currentPrice, setCurrentPrice] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [slotData, setSlotData] = useState(null);
  const [slotLoading, setSlotLoading] = useState(false);
  const [sameItemError, setSameItemError] = useState(false);
  const [dateforCalendar, setDateforCalendar] = useState(null);
  const { id } = useParams();
  //FETCH DETAILS EFFECT
  useEffect(() => {
    (async () => {
      let { data, success } = await fetchSingleProduct(id);
      if (success) {
        setProductDetails(data);
        setCurrentPrice(data?.startingPrice);
        setProductPrice(() => {
          if (data.discount > 0) {
            let discountedPrice = (
              data.startingPrice -
              (data.startingPrice * data.discount) / 100
            ).toFixed(2);
            formik.setFieldValue("price", discountedPrice);
            return discountedPrice;
          } else {
            formik.setFieldValue("price", data?.startingPrice);
            return data?.startingPrice;
          }
        });
        setIsLoading(false);
        formik.setFieldValue("product", data._id);
        formik.setFieldValue("serviceId", data?.services[0]?._id);
        formik.setFieldValue("selectedRoom", data?.room[0]?._id);
      }
    })();
  }, []);
  //EFFECT To GET AVAILABLE DATES FOR CALENDAR
  // useEffect(() => {
  //   let dates = [];
  //   let currentDate = moment().startOf("day"); // Start from the beginning of today
  //   for (let i = 0; i < 7; i++) {
  //     dates.push(currentDate.format("YYYY-M-DD")); // Push formatted date to array
  //     currentDate.add(1, "day"); // Move to next day
  //   }
  //   setCurrentWeekDates(dates);
  // }, []);

  const handleAddToCart = async (values) => {
    if (!values.date) {
      return toast.error("Invalid Date");
    }
    let dataForCart = {
      id: `${new Date().toISOString()}`,
      appointmentDetails: {
        ...values,
        date: moment(values.date).format("YYYY-M-DD"),
      },
      productDetails: productDetails,
      room: values.room,
      newPurchase: true,
    };
    await addItemToCart(dataForCart);
    handleClick("cart");
    setCurrentPrice(productDetails.startingPrice);
    setDateforCalendar(null);
    formik.resetForm();
    setProductPrice(() => {
      if (productDetails.discount > 0) {
        let discountedPrice = (
          productDetails.startingPrice -
          (productDetails.startingPrice * productDetails.discount) / 100
        ).toFixed(2);
        formik.setFieldValue("price", discountedPrice);
        return discountedPrice;
      } else {
        formik.setFieldValue("price", productDetails?.startingPrice);
        return productDetails?.startingPrice;
      }
    });
    formik.setFieldValue("product", productDetails._id);
    formik.setFieldValue("paymentMethod", "card");
    formik.setFieldValue("serviceId", productDetails.services[0]._id);
    formik.setFieldValue("selectedRoom", productDetails.room[0]._id);
    setSlotData(null);
    toast.success("Added To Cart");
  };
  const handleDateChange = async (date) => {
    setSlotLoading(true);
    setDateforCalendar(JSON.stringify(moment(date).format("YYYY-M-DD")));
    let dateForData = moment(date).format("YYYY-MM-DD");
    let dataToSend = {
      productId: id,
      selectedDate: dateForData,
      roomId: formik.values.selectedRoom,
    };
    let data = await fetchAvailableSlots(dataToSend);
    setSlotData(data);
    formik.setFieldValue("date", dateForData);
    formik.setFieldValue("startTime", null);
    formik.setFieldValue("endTime", null);
    setSlotLoading(false);
  };
  const handleServiceChange = async (service) => {
    let currentPrice = productDetails.services.filter(
      (ele) => ele._id === service
    )[0].price;
    let price =
      productDetails.discount > 0
        ? (
            currentPrice -
            (currentPrice * productDetails.discount) / 100
          ).toFixed(2)
        : currentPrice;
    setCurrentPrice(currentPrice);
    setProductPrice(price);
    setDateforCalendar(null);
    setSlotData(null);
    formik.setFieldValue("serviceId", service);
    formik.setFieldValue("price", price);
    formik.setFieldValue("date", null);
    formik.setFieldValue("startTime", null);
    formik.setFieldValue("endTime", null);
  };
  const handleRoomChange = async (room) => {
    setSlotData(null);
    setDateforCalendar(null);
    formik.setFieldValue("selectedRoom", room);
    formik.setFieldValue("date", null);
    formik.setFieldValue("startTime", null);
    formik.setFieldValue("endTime", null);
  };

  const checkTime = (newStartTime, newDate) => {
    let formattedDate = moment(newDate).format("YYYY-M-DD");
    for (const cartItem of item) {
      const { date, startTime } = cartItem.appointmentDetails;
      if (date === formattedDate && startTime === newStartTime) {
        return false;
      }
    }
    return true;
  };
  const handleTimeChange = async (time) => {
    if (checkTime(time, formik.values.date)) {
      setSameItemError(false);
      const startTime = moment(time, "hh:mma");
      const endTime = productDetails?.name?.includes("Red Light Therapy")
        ? startTime.add(30, "minutes")
        : startTime.add(50, "minutes");
      const formattedEndTime = endTime.format("hh:mma");
      formik.setFieldValue("startTime", time);
      formik.setFieldValue("endTime", formattedEndTime);
    } else {
      setSameItemError(true);
    }
  };

  const AppointmentSchema = Yup.object({
    date: Yup.string().required("Please Select Date"),
    startTime: Yup.string().required("Please Select Time"),
  });

  const formik = useFormik({
    initialValues: {
      product: "",
      selectedRoom: "",
      date: null,
      startTime: null,
      endTime: null,
      paymentMethod: "card",
      price: productPrice,
      serviceId: "",
    },
    validationSchema: AppointmentSchema,
    onSubmit: (values) => {
      handleAddToCart(values);
    },
  });

  return (
    <section className="text-body-text bg-product-body-bg body-font overflow-hidden">
      <div className="container px-5 py-24 mx-auto">
        <div className="lg:w-4/5 mx-auto flex flex-col flex-wrap">
          {isLoading ? (
            <Loading />
          ) : (
            <form className="w-full" onSubmit={formik.handleSubmit}>
              <div className="flex flex-wrap w-full">
                <div className="lg:w-1/2 w-full">
                  <Carousel
                    showArrows={false}
                    dynamicHeight={false}
                    infiniteLoop={true}
                    interval={5000}
                    autoPlay={true}
                    showIndicators={false}
                    showStatus={false}
                    thumbWidth={"100px"}
                    renderThumbs={() =>
                      productDetails.images.map((image, i) => (
                        <div
                          key={i}
                          className="h-28 border-black-shade border-2 bg-white-shade"
                        >
                          <img
                            src={image.url}
                            className="h-full w-full object-cover"
                          />
                        </div>
                      ))
                    }
                  >
                    {productDetails.images.map((image) => (
                      <div
                        className=" w-full lg:max-h-[30rem] max-h-96 bg-black-shade h-full rounded"
                        key={image._id}
                      >
                        <img
                          className=" w-full h-full object-contain rounded"
                          src={image.url}
                        />
                      </div>
                    ))}
                  </Carousel>
                </div>
                <div className="lg:w-1/2 w-full justify-around max-h-[30rem] min-h-max flex flex-col lg:pl-10 lg:py-4 ">
                  <h1 className="text-body-text text-4xl mt-3 lg:mt-0 title-font font-medium mb-1">
                    {productDetails.name}
                  </h1>
                  <div className="flex my-4">
                    <span className="title-font font-medium text-3xl text-body-heading">
                      ${productPrice}
                    </span>
                    {productDetails.discount > 0 && (
                      <p className="ml-2 text-body-heading leading-relaxed">
                        <span className="mr-2 text-black-shade line-through">
                          {currentPrice}$
                        </span>
                        {`Flat ${productDetails.discount}% off`}
                      </p>
                    )}
                  </div>
                  <p className="my-5 leading-relaxed">
                    {productDetails.description}
                  </p>
                  <div className="flex flex-col mt-6 items-center pb-5  ">
                    <div className="flex flex-col md:flex-row ml-2 w-full items-center">
                      <span className="md:mr-3 mb-2 md:mb-0 w-full md:w-1/6 lg:w-1/4 whitespace-nowrap">
                        Select Service
                      </span>
                      <div className="relative right-0 w-full">
                        <select
                          onChange={(e) => handleServiceChange(e.target.value)}
                          value={formik.values.serviceId}
                          className="rounded border w-full border-gray-700 focus:border-body-heading bg-transparent appearance-none py-2 focus:outline-none text-body-heading pl-3 pr-10"
                        >
                          {productDetails.services.map((service) => (
                            <option
                              key={service._id}
                              value={service._id}
                              className="py-2 px-1"
                            >
                              {service.name} - ${service.price}
                            </option>
                          ))}
                        </select>
                        <span className="absolute right-0 top-0 h-full w-10 text-center text-gray-600 pointer-events-none flex items-center justify-center">
                          <svg
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            className="w-4 h-4"
                            viewBox="0 0 24 24"
                          >
                            <path d="M6 9l6 6 6-6"></path>
                          </svg>
                        </span>
                      </div>
                    </div>
                    <div className="flex flex-col md:flex-row ml-2 mt-4 w-full items-center">
                      <span className="md:mr-3 mb-2 md:mb-0 w-full md:w-1/6 lg:w-1/4 whitespace-nowrap">
                        Select Room
                      </span>
                      <div className="relative right-0 w-full">
                        <select
                          name="selectedRoom"
                          value={formik.values.selectedRoom}
                          onChange={(e) => handleRoomChange(e.target.value)}
                          className="rounded border w-full border-gray-700 focus:border-body-heading bg-transparent appearance-none py-2 focus:outline-none text-body-heading pl-3 pr-10"
                        >
                          {productDetails.room?.map((room) => (
                            <option
                              key={room._id}
                              value={room._id}
                              className="py-2 px-1"
                            >
                              {room.name}
                            </option>
                          ))}
                        </select>
                        <span className="absolute right-0 top-0 h-full w-10 text-center text-gray-600 pointer-events-none flex items-center justify-center">
                          <svg
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            className="w-4 h-4"
                            viewBox="0 0 24 24"
                          >
                            <path d="M6 9l6 6 6-6"></path>
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="relative mt-3">
                <div className="mt-8 px-2 flex-col lg:flex-row flex w-full flex-wrap">
                  <div className=" min-h-max w-full lg:w-3/5 ">
                    <Calendar
                      tileClassName="p-2 sm:p-3 text-sm sm:text-base bg-gray-100"
                      next2Label={null}
                      prev2Label={null}
                      onChange={(value, e) => {
                        handleDateChange(value);
                      }}
                      className="min-h-max rounded-sm border flex flex-col border-black overflow-hidden"
                      value={dateforCalendar}
                      minDate={new Date()}
                    />
                    {formik.touched.date && formik.errors.date && (
                      <div className="mt-3 text-body-heading font-semibold">
                        {formik.errors.date}
                      </div>
                    )}
                  </div>

                  <div className="lg:pl-3 lg:mt-0 mt-4 w-full lg:w-2/5">
                    <div className="w-full min-h-max h-full flex flex-col">
                      <div className="h-full p-6 rounded-sm bg-white-shade border border-black-shade flex relative overflow-hidden">
                        {slotLoading ? (
                          <div className="h-full w-full flex items-center justify-center ">
                            <Loading
                              variant={"round"}
                              color={"#c78c4e"}
                              bgColor={"black"}
                              width="2rem"
                            />
                          </div>
                        ) : slotData === null ? (
                          <div className="h-full w-full flex items-center justify-center ">
                            <p className="text-body-heading font-semibold  text-sm">
                              Choose date to see available slots
                            </p>
                          </div>
                        ) : slotData.length <= 0 ? (
                          <div className="h-full flex items-center justify-center ">
                            <p className="text-body-heading font-semibold text-sm">
                              No available slots
                            </p>
                          </div>
                        ) : (
                          <div className="h-full w-full grid grid-cols-3">
                            {slotData.map((time, i) => (
                              <ShopTimeSlot
                                key={i}
                                onClick={() => handleTimeChange(time)}
                                value={formik.values.startTime}
                                time={time}
                              />
                            ))}
                          </div>
                        )}
                      </div>
                      {formik.touched.startTime && formik.errors.startTime && (
                        <div className="mt-3 text-body-heading font-semibold">
                          {formik.errors.startTime}
                        </div>
                      )}
                      {sameItemError && (
                        <div className="mt-3 text-body-heading font-semibold">
                          Appointment with same date and time already exist in
                          the cart
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {formik.values.date && (
                <div className="w-full flex gap-4 px-4 mt-4">
                  <div
                    className="p-4 mb-4 w-full text-sm text-white-shade rounded-lg bg-body-heading"
                    role="alert"
                  >
                    <span className="font-medium">
                      Cannot find the desired time ?
                    </span>{" "}
                    Try changing room.
                  </div>
                </div>
              )}
              <div className="w-full px-5 lg:px-20 mt-4">
                <AppointmentDetails
                  productDetails={productDetails}
                  service={formik.values.serviceId}
                  room={formik.values.selectedRoom}
                  date={formik.values.date}
                  startTime={formik.values.startTime}
                  endTime={formik.values.endTime}
                  totalAmount={
                    formik.values.price || productDetails.startingPrice
                  }
                />
              </div>
              <div className="w-full flex gap-4 px-20 mt-8">
                <button
                  type="submit"
                  className="w-full items-center transition-all duration-300 bg-btn-color border-0 py-3 text-white-shade px-3 focus:outline-none hover:bg-body-heading-hover rounded text-lg font-bold  md:mt-0"
                >
                  Add To Cart
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </section>
  );
};

export default ProductDetail;
