import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { FiSettings } from "react-icons/fi";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { Navbar, Sidebar, ThemeSettings } from "./components";
import {
  Ecommerce,
  Orders,
  Employees,
  Customers,
  Products,
  ProductEdit,
  CreateProduct,
  CreateRoom,
  Rooms,
  ProductDetail,
  AdminLogin,
  CustomerDetail,
  AppointmentDetail,
  WalkInCustomer,
  Coupons,
  CouponEdit,
  CreateCoupon,
  DisableSlots,
  EnableSlots,
  AdminProfile,
  WorkingHours,
} from "./pages";
import "./App.css";
import { useStateContext } from "./contexts/ContextProvider";
import { useAuthContext } from "./contexts/AuthContext";
import logo from "./data/logo.png";
const Home = () => {
  const {
    activeMenu,
    themeSettings,
    setThemeSettings,
    currentMode,
    currentColor,
  } = useStateContext();

  const { user, isAdmin, loading, isLoggedIn, isFrontDesk } = useAuthContext();
  return (
    <div className="flex relative dark:bg-main-dark-bg">
      <div className="fixed right-4 bottom-4" style={{ zIndex: "1000" }}>
        <TooltipComponent content="Settings" position="Top">
          <button
            type="button"
            className="text-3xl p-3 hover:drop-shadow-xl hover:bg-light-gray text-white"
            style={{ backgroundColor: currentColor, borderRadius: "50%" }}
            onClick={() => setThemeSettings(true)}
          >
            <FiSettings />
          </button>
        </TooltipComponent>
      </div>
      {activeMenu ? (
        <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white">
          <Sidebar />
        </div>
      ) : (
        <div className="w-0 dark:bg-secondary-dark-bg">
          <Sidebar />
        </div>
      )}
      <div
        className={`dark:bg-main-dark-bg transition-all bg-main-bg min-h-screen w-full ${
          activeMenu ? "md:ml-72 sm:pr-72 md:pr-0" : "flex-2"
        }`}
      >
        <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full">
          <Navbar />
        </div>
        <div>
          {themeSettings && <ThemeSettings />}
          <Routes>
            <Route
              path="/*"
              element={
                <Navigate
                  to={isAdmin ? "/admin/ecommerce" : "/admin/appointments"}
                />
              }
            />
            <Route
              path="/ecommerce"
              element={isAdmin ? <Ecommerce /> : <Navigate to={"/admin/"} />}
            />
            <Route
              path="/disable-slots"
              element={isAdmin ? <DisableSlots /> : <Navigate to={"/admin/"} />}
            />
            <Route
              path="/enable-slots"
              element={isAdmin ? <EnableSlots /> : <Navigate to={"/admin/"} />}
            />
            <Route path="/working-hours" element={<WorkingHours />} />
            <Route path="/profile" element={<AdminProfile />} />
            <Route path="/appointments" element={<Orders />} />
            <Route path="/walk-in-customer" element={<WalkInCustomer />} />
            <Route path="/appointment/:id" element={<AppointmentDetail />} />
            <Route path="/employees" element={<Employees />} />
            <Route path="/customers" element={<Customers />} />
            <Route path="/customer/:id" element={<CustomerDetail />} />
            <Route path="/products" element={<Products />} />
            <Route path="/rooms" element={<Rooms />} />
            <Route path="/coupons" element={<Coupons />} />
            <Route path="/create-coupon" element={<CreateCoupon />} />
            <Route path="/edit-coupon/:id" element={<CouponEdit />} />
            <Route path="/edit-product/:id" element={<ProductEdit />} />
            <Route path="/product/:id" element={<ProductDetail />} />
            <Route path="/create-product" element={<CreateProduct />} />
            <Route path="/create-room" element={<CreateRoom />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default Home;
